$primary: #ff6a04;
$secondary: #bf3a01;
$white: #fff;
$grey: #383838;
$darkGrey: #42484d;
$danger: #e02e2e;
$danger2: #d3465a;
$backgroundPrimary: #0f0f0f;
$backgroundSecondary: lighten($backgroundPrimary, 4);
// $backgroundTertiary: lighten($backgroundPrimary, 18);
$backgroundTertiary: #363636;

$secondary0: #02244d;
$secondary1: #011d3d;

$font: 'Open Sans';

$textGrey: #9b9b9b;
$textWhite: #fff;
$textBlack: #000;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;

$iconFillColor: #fff;
